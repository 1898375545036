@mixin flex {
  display: flex;
}

@mixin flex_jc_between {
  @include flex;
  justify-content: space-between;
}

@mixin flex_jc_evenly {
  @include flex;
  justify-content: space-evenly;
}

@mixin flex_jc_center {
  @include flex;
  justify-content: center;
}

@mixin flex_jc_start {
  @include flex;
  justify-content: flex-start;
}

@mixin flex_ai_center {
  @include flex;
  align-items: center;
}

@mixin flex_ai_end {
  @include flex;
  align-items: flex-end;
}

@mixin flex_start {
  @include flex;
  justify-content: flex-start;
}

@mixin flex_center {
  @include flex_ai_center;
  justify-content: center;
}

@mixin flex_end {
  @include flex;
  justify-content: flex-end;
}

@mixin flex_column {
  @include flex;
  flex-direction: column;
}

@mixin flex_between_center {
  @include flex_jc_between;
  align-items: center;
}

@mixin flex_between_end {
  @include flex_jc_between;
  align-items: flex-end;
}

@mixin flex_evenly_center {
  @include flex_jc_evenly;
  align-items: center;
}
@mixin flex_center_end {
  @include flex_ai_end;
  justify-content: center;
}
