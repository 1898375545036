$font: 'Poppins', sans-serif;

:root {
  --widthBreakpoint: 100rem;
}
.ws {
  --widthBreakpoint: 100vw;
}

$navbarHeight: 4.5rem;
$widthBreakpoint: var(--widthBreakpoint);
