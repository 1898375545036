@import './styles/index.scss';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
  font-family: $font;
  background: $rich_black;
  color: $white;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: $font;
  line-height: 1.4;
}

h1 {
  font-weight: 700;
  font-size: 2.25rem;
}

h2 {
  font-weight: 700;
  font-size: 2rem;
}

h3 {
  font-weight: 600;
  font-size: 1.75rem;
}

h4 {
  font-weight: 600;
  font-size: 1.5rem;
}

h5 {
  font-weight: 500;
  font-size: 1.25rem;
}

h6 {
  font-weight: 500;
  font-size: 1rem;
}

p {
  font-weight: 400;
  font-size: 1rem;
}

a {
  font: 400 1rem/1.4 $font;
  color: $wild_blue_yonder;
  cursor: pointer;
  text-decoration: none;
}

a.active {
  color: $emerald;
}

.pointer {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 0.35rem;
  height: 0.35rem;
}
::-webkit-scrollbar-track {
  // border-radius: 5px;
  background: transparent;
  visibility: hidden;
}
::-webkit-scrollbar-thumb {
  background: $emerald;
  border-radius: 0.5rem;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba($color: $emerald, $alpha: 0.8);
}
