$black: #000;
$white: #fff;
$jet: #333;

$emerald: #3cd27d;
$wild_blue_yonder: #92abcf;
$wild_blue_yonder: #91aace;
$fluorescent_blue: #10ece5;
$light_periwinkle: #c7d3e7;
$light_periwinkle: #c6d3e7;
$rich_black_forga: #0e1724;
$rich_black_fogra_transparent: rgba(14, 24, 37, 0.6);
$rich_black: #212121;
$raisin_black: #1a212d;
$space_cadet: #2d384e;
$sunglow: #ffcd38;
$cerulean_frost: #869ebf;
$independence: #32405a;
$rich_black_fogra: #0e1825;
$light_stell_blue: #bdcadd;
$oxford_blue: #1c2b43;
$dark_blue: #0d1825;
$brown: #be7c26;
$medium_spring_green: rgb(0, 250, 154)
