.readmeContainer {
  padding: 16px;
  * {
    margin: revert;
  }

  ul,
  li {
    margin: 14px 16px;
  }

  .sectionContainer {
    padding: 0px 16px;
  }
}
