.FolderTree .CheckBox {
  display: inline-block;
  margin-right: 3px;
}
.FolderTree .TreeNode {
  padding: 2px 0;
}
.FolderTree .TreeNode .TreeNodeToolBar {
  margin-left: 3px;
}
.FolderTree .TreeNode .TreeNodeToolBar svg {
  padding: 0 2px;
}
.FolderTree .TreeNode .iconContainer {
  padding: 0 2px;
}
.FolderTree .TreeNode .iconContainer svg {
  padding-top: 1px;
  vertical-align: text-top;
  color: white;
}
.FolderTree .TreeNode .iconContainer:not(.typeIconContainer) svg:hover {
  cursor: pointer;
}
.FolderTree .EditableName .displayName:hover {
  cursor: pointer;
}
.displayName {
  position: relative;
  top: 2px;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #c6d3e7;
}
.FolderTree .EditableName .editableNameToolbar {
  margin-left: 3px;
}
.FolderTree .EditableName .editableNameToolbar svg {
  margin: 0 2px;
}
.FolderTree .icon:hover.OKIcon {
  color: #5cd65c;
}
.FolderTree .icon:hover.CancelIcon {
  color: #f33;
}
.FolderTree .icon:hover.EditIcon {
  color: #b3f;
}
.FolderTree .icon:hover.DeleteIcon {
  color: #ff66b3;
}
.FolderTree .icon:hover.AddFileIcon {
  color: #009973;
}
.FolderTree .icon:hover.AddFolderIcon {
  color: #009973;
}
