@import '../../styles/index.scss';

%item-common-style {
  height: calc(100vh - #{$navbarHeight} - 1rem);
  overflow-y: auto;
  background-color: $rich_black_fogra;
}

%common-font-styles {
  font-family: $font;
  font-weight: 400;
  font-style: normal;
}

.homeContainer {
  @include flex;
  margin-top: 0.688rem;
  align-items: stretch;
  gap: 0.688rem;
}

.projectExplorerItem {
  width: 23.2rem;
  position: relative;
  flex-grow: 0.7;
  overflow-x: auto;
  @extend %item-common-style;
}

.editorItem {
  flex-grow: 3;
  position: relative;
  @extend %item-common-style;
}

.headerSection {
  @include flex_jc_between;
  background-color: $oxford_blue;
  height: 2.875rem;
  position: sticky;
  left: 0px;
  width: auto;
  @extend %common-font-styles;
  font-size: 1.125rem;
  color: $white;
  align-items: center;
  padding-left: 4px;
}
.editorHeaderSection {
  background-color: $oxford_blue;
  height: 2.875rem;
  @extend %common-font-styles;
  font-size: 1.125rem;
  color: $white;
  padding-left: 1rem;
  align-items: center;
  @include flex_jc_between;
  border-bottom: 1px solid $wild_blue_yonder;
}
.refreshIcon {
  position: relative;
  right: 2.3rem;
  color: $emerald;
  top: 0.125rem;
  cursor: pointer;
}

.projectExplorerBlock {
  margin-top: 1.063rem;
}

.fileIcon {
  position: relative;
  right: 0.625rem;
}

.fileName {
  padding-left: 0.625rem;
  @include flex_ai_center;
  color: $medium_spring_green;
}

.extraButtons {
  width: 11rem;
  @include flex_evenly_center;
  margin-right: 0.375rem;
  cursor: pointer;
  svg {
    color: $emerald;
  }
}

.previewIcon {
  position: relative;
  top: 0.313rem;
}

.roleDoesNotExistMessage {
  text-align: center;
  width: -webkit-fill-available;
  margin-top: 1rem;
}

.loaderContainer {
  width: 100%;
  max-width: 100rem;
  height: calc(100vh - 72px);
  background: $rich_black_fogra_transparent;
  position: absolute;
  @include flex_center;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $emerald;
  z-index: 1000;
  p {
    margin-top: 10px;
    margin-left: 10px;
  }
}

.disabled {
  cursor: not-allowed;
}

.enabled {
  cursor: pointer;
}
