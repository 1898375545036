@import '../../styles/index.scss';

.savetocloud {
  height: 30px;
  border: 1px solid $dark_blue;
  background-color: #11ece5;
  border-radius: 4px;
  float: right;
  color: #0e1521;
  cursor: pointer;
}

.arrowRight {
  transform: rotate(-90deg);
  position: relative;
  top: 0.125rem;
  left: 0.313rem;
}

.arrowDown {
  position: relative;
  bottom: 0.125rem;
  left: 0.35rem;
  top: 1px;
}

.column {
  @include flex_ai_center;
  cursor: pointer;
  color:$white;
  margin-left: 28px;
  margin-bottom: 5px;
}

.ul {
  list-style-type: none;
  margin: 5px 0;
}

.loadingText {
  color: $emerald;
  padding: 0.25rem 1rem;
}

.projectName {
  color: $brown;
  position: relative;
  bottom: 2px;
  margin-right: 5px;
}
select {
  background: $dark_blue;
  width: 94.5%;
  margin: 0 10px 5px 0px;
  padding: 10px 10px 10px 0;
  height: 100%;
  color: $white;
  font-size: 16px;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 4px;
  font-family: $font;
}

.dropdownWrapper {
  @include flex_jc_center;
}

.folder {
  margin: 8px 0 0 4px;
  color: $sunglow;
}
