@import '../../styles/index.scss';

.container {
  max-width: $widthBreakpoint;
  width: 100%;
  margin: 0 auto;
}

.layoutContainer {
  position: absolute;
  top: $navbarHeight;
  z-index: 0;
  width: 100%;
  background-color: $black;
  max-width: $widthBreakpoint;
  min-height: calc(100vh - #{$navbarHeight});
}

.toast {
  max-width: fit-content;
  color: $emerald;
  position: absolute;
  top: 36px;
  z-index: 1000;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 8px 16px;
  border: 2px solid;
  border-radius: 8px;
  background-color: $oxford_blue;
}
