@import '../../../styles/index.scss';

.menuOverlay {
  position: absolute;
  top: 100%;
  right: 0;
  width: 300px;
  border-radius: 5px;
  background: $space_cadet;
  color: $white;
  max-height: 500px;
  overflow: hidden;
  font: 400 0.875rem $font;
  animation: fadeOverlay 0.2s ease-in;
  .menuGroup {
    padding: 1rem;
    .fullName {
      padding-bottom: 0.25rem;
      color: $wild_blue_yonder;
    }
    .menuHeading {
      color: $fluorescent_blue;
    }
    .menuIcon {
      margin-right: 0.25rem;
    }
    .menuItem {
      padding: 0.5rem 1.75rem;
      &:hover {
        font-weight: 500;
        cursor: pointer;
      }
    }
    &:not(:last-child) {
      border-bottom: 1px solid $wild_blue_yonder;
      .active {
        font-weight: 500;
      }
    }
    .active {
      padding: 0.5rem 0;
      @include flex_ai_center;
    }
  }
}

@keyframes fadeOverlay {
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 500px;
  }
}
