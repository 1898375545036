@import '../../styles/index.scss';

.navbarContainer {
  @include flex_jc_between;
  width: 100%;
  height: $navbarHeight;
  max-width: $widthBreakpoint;
  padding: 0.625rem 2.125rem;
  background-color: $rich_black_fogra;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  %vertical-common-style {
    border-left: 1px solid $wild_blue_yonder;
    height: 2.875rem;
    margin-top: 0.4rem;
  }
  .leftVerticalLine {
    @extend %vertical-common-style;
    margin-left: 0.6rem;
  }
  .rightVerticalLine {
    @extend %vertical-common-style;
  }
  .cloudEditor {
    font-family: $font;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    color: $white;
    text-align: left;
    display: flex;
    align-items: flex-end;
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .brandIcon {
    cursor: pointer;
  }
  .menuGroup {
    margin-left: 0.2rem;
    margin-right: auto;
    list-style-type: none;
    @include flex_ai_end;
    .menuItem {
      margin: 0 0.75rem;
      & .active {
        color: $emerald;
      }
    }
  }
  .userInfoContainer {
    @include flex_ai_center;
    .userInfo {
      @include flex_column;
      font: 400 0.875rem $font;
      user-select: none;
      .userName {
        color: $wild_blue_yonder;
        text-align: right;
        padding-right: 24px;
      }
      .domainName {
        color: $fluorescent_blue;
        @include flex_ai_center;
        cursor: pointer;
      }
    }
    .userIcon {
      height: 2.5rem;
      width: 2.5rem;
      fill: $wild_blue_yonder;
    }
  }
}
